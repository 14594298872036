import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/error": [~6],
		"/healthcheck": [7],
		"/login": [8],
		"/logout": [9],
		"/org/[org_id]": [10],
		"/title/[title_id]": [11],
		"/title/[title_id]/[env_id]": [12,[2]],
		"/title/[title_id]/[env_id]/commerce": [15,[2,4]],
		"/title/[title_id]/[env_id]/commerce/[deployment_id]": [16,[2,4]],
		"/title/[title_id]/[env_id]/players": [19,[2]],
		"/title/[title_id]/[env_id]/player/[mothership_player_id]": [17,[2]],
		"/title/[title_id]/[env_id]/player/[mothership_player_id]/user-data": [18,[2]],
		"/title/[title_id]/[env_id]/user-data": [20,[2]],
		"/title/[title_id]/[env_id]/[deployment_id]": [13,[2,3]],
		"/title/[title_id]/[env_id]/[deployment_id]/title-data": [14,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';